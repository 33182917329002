<template>
  <div>
    <HelpTop />
    <div class="site-content">
      <div class="term containerBox">
        <h1 class="term-name">{{ showList.listTit }}</h1>
      </div>

      <ul class="containerBox">
        <li
          :key="itm"
          v-for="(itm, ix) in showList.data"
          @click="openCont(itm, ix + 1)"
        >
          <span class="post-title">{{
            (ix + 1).toString() + ". " + itm.titel
          }}</span>
          <p class="post-excerpt">{{ itm.content }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import HelpTop from "@/components/HelpTop.vue";
import { mapState } from "vuex";
export default {
  components: {
    HelpTop,
  },
  computed: {
    ...mapState({
      allData: (state) => state.listData,
      list: (state) => state.list,
    }),
  },
  name: "helpCont",
  created() {
    var query = this.$route.query;
    console.log("query = ", query);
    this.$store.commit("fetchList", { listKey: query.listKey });
    if (this.list) {
      this.showList = this.list;
    } else {
      this.showList = {};
      this.$router.push("/help");
    }
  },

  data() {
    return {
      showList: null,
    };
  },
  mounted() {},
  beforeMount() {
    document.scrollingElement.scrollTop = 0;
  },
  methods: {
    openCont(item, idx) {
      this.$router.push({
        path: "/helpCont",
        query: { listKey: this.showList.key, contKey: item.key, idx: idx },
      });

      this.$store.commit("updateCont", { data: item, idx });
    },
  },
  watch: {
    list(newValue, oldValue) {
      this.showList = newValue;
    },
  },
};
</script>
<style>
.post-feed {
  position: relative;
  display: flex;
  padding: 35px 0 33px;
  flex-wrap: wrap;
}
.related-posts {
  margin-top: 60px;
}
.related-title {
  margin-bottom: 0;
  color: #999;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.post-title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
}
.content-area {
  flex-grow: 1;
  padding-top: 60px;
  padding-bottom: 30px;
}
.post-header {
  margin-right: auto;
  margin-left: auto;
  padding-right: 4vw;
  padding-left: 4vw;
  max-width: calc(1130px + 8vw);
  width: 100%;
}
.post-title {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 500;
}
.kg-canvas {
  margin-top: 45px;
}
.kg-image {
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 100%;
  height: auto;
}
.site-content {
  flex-grow: 1;
  padding-top: 60px;
  padding-bottom: 30px;
}
.term {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}
.containerBox {
  margin-right: auto;
  margin-left: auto;
  padding-right: 4vw;
  padding-left: 4vw;
  max-width: calc(1130px + 8vw);
  width: 100%;
  list-style-type: none;
}
.containerBox li {
  display: flex;
  padding: 35px 0 33px;
  border-bottom: 1px solid #e0dddd;
  flex-wrap: wrap;
  cursor: pointer;
}
.term-name {
  margin-bottom: 0;
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 1.3;
  font-weight: 700;
  color: #6c5ce7;
}
.post-title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}
.post-excerpt {
  color: #999;
  font-size: 15px;
  line-height: 1.5;
}
</style>
