<template>
  <div class="help">
    <div class="site">
      <HelpTop />
      <div class="site-content">
        <div class="content-area">
          <main class="site-main container">
            <div class="topic-feed">
              <div class="row">
                <div class="col-md-6" :key="item" v-for="item in list">
                  <section class="topic">
                    <header class="topic-header">
                      <h2 class="topic-name">{{ item.listTit }}</h2>
                      <!-- <div class="topic-count">
             {{item.data.length}} 篇文章 
           </div>  -->
                    </header>
                    <ul
                      class="topic-article-feed u-plain-list"
                      style="cursor: pointer"
                    >
                      <li
                        v-for="(it, ix) in item.data"
                        :key="ix"
                        class="topic-article"
                        @click="openCont(item.key, it, ix + 1)"
                      >
                        <h3 class="topic-article-title">
                          <a class="topic-article-link"> </a
                          >{{ (ix + 1).toString() + ". " + it.titel }}
                        </h3>
                      </li>
                    </ul>
                    <a
                      @click="goList(item)"
                      style="cursor: pointer"
                      class="topic-more button-arrow button-arrow-right"
                      >{{ $t("message.help_cksy") }}</a
                    >
                  </section>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <!-- <footer class="site-footer container"> 
    <div class="footer-social"> 
     <a class="footer-social-item footer-social-item-facebook" href="https://www.facebook.com/yibifuexchange" target="_blank" rel="noopener noreferrer" aria-label="Facebook"> <i class="icon icon-facebook"></i> </a> 
     <a class="footer-social-item footer-social-item-twitter" href="https://twitter.com/yibifuexchange" target="_blank" rel="noopener noreferrer" aria-label="Twitter"> <i class="icon icon-twitter"></i> </a> 
     <a class="footer-social-item footer-social-item-rss" href="https://feedly.com/i/subscription/feed/http://help.yibifu.one/rss/" target="_blank" rel="noopener noreferrer" aria-label="RSS"> <i class="icon icon-rss"></i> </a> 
    </div> 
    <div class="copyright">
      Powered by 
     <a href="https://ghost.org/" target="_blank">Ghost</a> 
    </div> 
   </footer>  -->
    </div>
  </div>
</template>
<script>
import HelpTop from "@/components/HelpTop.vue";
import { mapState } from "vuex";
export default {
  name: "help",
  components: {
    HelpTop,
  },
  computed: {
    ...mapState({
      helpAllData: (state) => state.listData,
    }),
  },
  data() {
    return {
      list: null,
    };
  },
  created() {
    this.list = this.helpAllData;
  },
  methods: {
    goList(item) {
      this.$router.push({
        path: "/helpList",
        query: { listKey: item.key },
      });
      this.$store.commit("updateList", item);
    },
    openCont(listKey, item, idx) {
      this.$router.push({
        path: "/helpCont",
        query: { listKey, contKey: item.key, idx: idx },
      });
      this.$store.commit("updateCont", { data: item, idx: idx });
    },
  },
  mounted() {
    // this.$store.commit("updatelistData", this.list);
  },
  beforeMount() {
    document.scrollingElement.scrollTop = 0;
  },
};
</script>
<style>
body,
html {
  background: #fff;
}
.post-feed {
  position: relative;
  display: flex;
  padding: 35px 0 33px;
  flex-wrap: wrap;
}
.related-posts {
  margin-top: 60px;
}
.related-title {
  margin-bottom: 0;
  color: #999;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.post-title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
}
.content-area {
  flex-grow: 1;
  padding-top: 60px;
  padding-bottom: 30px;
}
.post-header {
  margin-right: auto;
  margin-left: auto;
  padding-right: 4vw;
  padding-left: 4vw;
  max-width: calc(1130px + 8vw);
  width: 100%;
}
.post-title {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 500;
}
.kg-canvas {
  margin-top: 45px;
}
.kg-image {
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 100%;
  height: auto;
}
.site-content {
  flex-grow: 1;
  padding-top: 60px;
  padding-bottom: 30px;
}
.term {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}
.containerBox {
  margin-right: auto;
  margin-left: auto;
  padding-right: 4vw;
  padding-left: 4vw;
  max-width: calc(1130px + 8vw);
  width: 100%;
  list-style-type: none;
}
.containerBox li {
  display: flex;
  padding: 35px 0 33px;
  border-bottom: 1px solid #e0dddd;
  flex-wrap: wrap;
  cursor: pointer;
}
.term-name {
  margin-bottom: 0;
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 1.3;
  font-weight: 700;
  color: #6c5ce7;
}
.post-title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}
.post-excerpt {
  color: #999;
  font-size: 15px;
  line-height: 1.5;
}
</style>
