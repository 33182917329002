import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import zh from './locales/zh.json'

// // 获取浏览器语言
// const browserLanguage = navigator.language || navigator.userLanguage
const browserLanguage = navigator.language
const defaultLanguage = browserLanguage.includes('en') ? 'en' : 'zh'

const i18n = createI18n({
    locale: defaultLanguage,
    messages: { en, zh }
  })


  export default i18n