<template>
    <div>
        <HelpTop />
        <div class="site-content" v-if="cont_list.code == 'serve'||this.$route.query.code == 'serve'">
            <div class="content-area">
                <div class="site-main">
                    <div class="post-header serve-header">
                        <h1 class="post-title">{{ $t('message.ubfwxy') }}</h1>
                    </div>
                    <div class="kg-canvas">
                        <p>{{ $t('message.ubfwxyyd1') }}
                          {{ $t('message.ubfwxyyd2') }}
                          {{ $t('message.ubfwxyyd3') }}
                          {{ $t('message.ubfwxyyd4') }}</p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.zyts') }}</b>
                        <p>
                          {{ $t('message.wmzctytx') }}</br>
                          {{ $t('message.wmzctytx1') }}</br>
                          {{ $t('message.wmzctytx2') }}</br>
                          {{ $t('message.wmzctytx3') }}</br>
                          {{ $t('message.wmzctytx4') }}
                          {{ $t('message.wmzctytx41') }}</br>
                          {{ $t('message.wmzctytx5') }}

                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.fwnr') }}</b>
                        <p>
                          {{ $t('message.fwnrt') }}</br>
                          {{ $t('message.fwnr1') }}</br>
                             {{ $t('message.fwnr11') }}</br>
                             {{ $t('message.fwnr12') }}</br>
                          {{ $t('message.fwnr2') }}</br>
                          {{ $t('message.fwnr3') }}</br>
                          {{ $t('message.fwnr4') }}</br>

                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.fwgzt') }}</b>
                        <p>
                          {{ $t('message.fwgzt0') }}</br>
                          {{ $t('message.fwgzt1') }}</br>
                          {{ $t('message.fwgzt2') }}</br>
                          {{ $t('message.fwgzt3') }}</br>
                          {{ $t('message.fwgzt4') }}</br>
                          {{ $t('message.fwgzt5') }}</br>
                          {{ $t('message.fwgzt6') }}</br>
                          {{ $t('message.fwgzt7') }}</br>

                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.jygzt') }}</b>
                        <p>
                          {{ $t('message.jygzt0') }}</br>
                          {{ $t('message.jygzt1') }}</br>
                          {{ $t('message.jygzt2') }}</br>
                          {{ $t('message.jygzt3') }}</br>
                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.fwdxgzj') }}</b>
                        <p>
                          {{ $t('message.fwdxgzj0') }}</br>
                          {{ $t('message.fwdxgzj1') }}</br>
                          {{ $t('message.fwdxgzj2') }}</br>
                          {{ $t('message.fwdxgzj3') }}</br>
                          {{ $t('message.fwdxgzj4') }}</br>
                          {{ $t('message.fwdxgzj5') }}</br>
                          {{ $t('message.fwdxgzj6') }}</br>
                          {{ $t('message.fwdxgzj7') }}</br>
                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.yhxxbm') }}</b>
                        <p>
                          {{ $t('message.yhxxbm1') }}</br>
                          {{ $t('message.yhxxbm11') }}</br>
                          {{ $t('message.yhxxbm12') }}</br>
                          {{ $t('message.yhxxbm12') }}</br>
                          {{ $t('message.yhxxbm14') }}</br>
                          {{ $t('message.yhxxbm2') }}</br>
                          {{ $t('message.yhxxbm21') }}</br>
                          {{ $t('message.yhxxbm22') }}</br>
                          {{ $t('message.yhxxbm23') }}</br>
                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.yhqlt') }}</b>
                        <p>
                          {{ $t('message.yhqlt1') }}</br>
                          {{ $t('message.yhqlt11') }}</br>
                          {{ $t('message.yhqlt12') }}</br>
                          {{ $t('message.yhqlt13') }}</br>
                          {{ $t('message.yhqlt14') }}</br>
                          {{ $t('message.yhqlt15') }}</br>
                          {{ $t('message.yhqlt2') }}</br>
                          {{ $t('message.yhqlt3') }}</br>
                          {{ $t('message.yhqlt4') }}</br>
                          {{ $t('message.yhqlt5') }}</br>
                          {{ $t('message.yhqlt6') }}</br>
                          {{ $t('message.yhqlt7') }}</br>
                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.yhywt') }}</b>
                        <p>
                          {{ $t('message.yhywt1') }}</br>
                          {{ $t('message.yhywt11') }}</br>
                          {{ $t('message.yhywt12') }}</br>
                          {{ $t('message.yhywt13') }}</br>
                          {{ $t('message.yhywt14') }}</br>
                          {{ $t('message.yhywt15') }}</br>
                          {{ $t('message.yhywt16') }}</br>
                          {{ $t('message.yhywt17') }}</br>
                          {{ $t('message.yhywt18') }}</br>
                          {{ $t('message.yhywt19') }}</br>
                          {{ $t('message.yhywt110') }}</br>
                          {{ $t('message.yhywt2') }}</br>
                          {{ $t('message.yhywt3') }}</br>
                          {{ $t('message.yhywt4') }}</br>
                          {{ $t('message.yhywt5') }}</br>
                          {{ $t('message.yhywt6') }}</br>
                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.jjdbmzt') }}</b>
                        <p>
                          {{ $t('message.jjdbmzt1') }}</br>
                          {{ $t('message.jjdbmzt2') }}</br>
                          {{ $t('message.jjdbmzt3') }}</br>
                          {{ $t('message.jjdbmzt4') }}</br>
                          {{ $t('message.jjdbmzt5') }}</br>
                          {{ $t('message.jjdbmzt6') }}</br>
                          {{ $t('message.jjdbmzt7') }}</br>
                          {{ $t('message.jjdbmzt8') }}</br>
                        
                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.gyxyt') }}</b>
                        <p>
                          {{ $t('message.gyxyt1') }}</br>
                          {{ $t('message.gyxyt2') }}</br>
                          {{ $t('message.gyxyt3') }}</br>
                          {{ $t('message.gyxyt4') }}</br>
                          {{ $t('message.gyxyt5') }}</br>
                        
                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.ljkhfxq') }}</b>
                        <b>{{ $t('message.ljkhfxq1') }}</b>
                        <p>
                          {{ $t('message.ljkhfxq11') }}</br>
                          {{ $t('message.ljkhfxq12') }}</br>
                        
                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.ljkhfxq2') }}</b>
                        <p>
                          {{ $t('message.ljkhfxq21') }}</br>
                          {{ $t('message.ljkhfxq22') }}</br>
                          {{ $t('message.ljkhfxq23') }}</br>
                          {{ $t('message.ljkhfxq24') }}</br>
                          {{ $t('message.ljkhfxq25') }}</br>
                          {{ $t('message.ljkhfxq26') }}</br>
                          {{ $t('message.ljkhfxq27') }}</br>
                          {{ $t('message.ljkhfxq28') }}</br>
                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.sfxxksqr3') }}</b>
                        <p>
                          {{ $t('message.sfxxksqr31') }}</br>
                          {{ $t('message.sfxxksqr311') }}</br>
                          {{ $t('message.sfxxksqr3111') }}</br>
                          {{ $t('message.sfxxksqr3112') }}</br>
                          {{ $t('message.sfxxksqr3113') }}</br>
                          {{ $t('message.sfxxksqr312') }}</br>
                          {{ $t('message.sfxxksqr3121') }}</br>
                          {{ $t('message.sfxxksqr3122') }}</br>
                          {{ $t('message.sfxxksqr313') }}</br>
                          {{ $t('message.sfxxksqr32') }}</br>
                          {{ $t('message.sfxxksqr321') }}</br>
                          {{ $t('message.sfxxksqr322') }}</br>
                          {{ $t('message.sfxxksqr323') }}</br>
                          {{ $t('message.sfxxksqr324') }}</br>
                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.jkjyt4') }}</b>
                        <p>
                          {{ $t('message.jkjyt41') }}</br>
                          {{ $t('message.jkjyt42') }}</br>
                          {{ $t('message.jkjyt43') }}</br>
                          {{ $t('message.jkjyt44') }}</br>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="site-content" v-if="cont_list.code == 'secrecy'||this.$route.query.code == 'secrecy'">
            <div class="content-area">
                <div class="site-main">
                    <div class="post-header serve-header">
                        <h1 class="post-title">{{ $t('message.yszct') }}</h1>
                    </div>
                    <div class="kg-canvas">
                        <p>{{ $t('message.yszct0') }}</p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.yszct1') }}</b>
                        <p>
                          {{ $t('message.yszct11') }}</br>
                          {{ $t('message.yszct12') }}</br>
                          {{ $t('message.yszct13') }}</br>
                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.yszct14') }}</b>
                        <p>
                          {{ $t('message.yszct15') }}</br>
                          {{ $t('message.yszct16') }}</br>
                          {{ $t('message.yszct17') }}</br>
                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.yszct2') }}</b>
                        <p>
                          {{ $t('message.yszct21') }}</br>
                          {{ $t('message.yszct22') }}</br>
                          {{ $t('message.yszct23') }}</br>
                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.yszct3') }}</b>
                        <p>
                          {{ $t('message.yszct30') }}</br>
                          {{ $t('message.yszct31') }}</br>
                          {{ $t('message.yszct32') }}</br>
                          {{ $t('message.yszct33') }}</br>
                          {{ $t('message.yszct34') }}</br>
                          {{ $t('message.yszct35') }}</br>
                          {{ $t('message.yszct36') }}</br>
                          {{ $t('message.yszct37') }}</br>
                        </p>
                    </div>
                    <div class="kg-canvas">
                        <b>{{ $t('message.yszct4') }}</b>
                        <p>
                          {{ $t('message.yszct41') }}</br>
                          {{ $t('message.yszct42') }}</br>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="site-content" v-else>
            <div class="content-area">
                <div class="site-main">
                    <div class="post-header">
                        <h1 class="post-title">{{ idx +  cont_list.titel }}</h1>
                    </div>
                    <div class="kg-canvas" :key="ixd" v-for="(itam, ixd) in cont_list.link">
                        <div :class="{ active: itam.style == 'red' }" style="white-space: pre-line">{{ itam.h1 }}</div>
                        <div v-if="itam.subh1" class="active" style="white-space: pre-line">{{ itam.subh1 }}</div>
                        <div class="twoImage">
                            <img :src="itam.image" class="kg-image">

                            <img  :src="itam.image1" class="kg-image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HelpTop from '@/components/HelpTop.vue'
import { mapState } from 'vuex';
export default {
    components: {
        HelpTop
    },
    computed: {
        ...mapState({
            cont: state => state.cont
        }),
        
    },
    name: 'helpCont',
    data() {
        return {
            cont_list: null,
            idx : 0
        }
    },
    created() {
        var query = this.$route.query
        this.$store.commit("fetchCont", { listKey: query.listKey, contKey: query.contKey, idx: query.idx});
        
        console.log('this.cont = ', this.cont)
        if(this.cont.data) {
            const {data, idx} = this.cont
            this.cont_list = data; // 在组件的 created 生命周期钩子函数中，将映射的 list 数据赋值给组件的 data 属性 listData
            this.idx = idx != undefined ? idx + ". ": ""; 
        } else {
            this.cont_list = {}
            this.$router.push("/help");
        }
    },
    beforeMount () {
        document.scrollingElement.scrollTop = 0
    },
    
}
</script>
<style >

.twoImage {
    display: flex;
    flex-direction: row;
    gap: 30px;

 }

@media (max-width: 768px) {
    .twoImage {
    display: flex;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
 }
}



.post-feed {
    position: relative;
    display: flex;
    padding: 35px 0 33px;
    flex-wrap: wrap;
}

.related-posts {
    margin-top: 60px;
}

.related-title {
    margin-bottom: 0;
    color: #999;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.post-title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
}

.content-area {
    flex-grow: 1;
    padding-top: 60px;
    padding-bottom: 30px;
}

.post-header {
    margin-right: auto;
    margin-left: auto;
    padding-right: 4vw;
    padding-left: 4vw;
    max-width: calc(1130px + 8vw);
    width: 100%;

}

.post-title {
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 500;
}

.kg-canvas {
    margin-top: 45px;
}

.kg-canvas .active {
    color: red;
}

.kg-image {
    margin-right: auto;
    margin-left: auto;
    display: block;
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.site-content {
    flex-grow: 1;
    padding-top: 60px;
    padding-bottom: 30px;
}

.term {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.containerBox {
    margin-right: auto;
    margin-left: auto;
    padding-right: 4vw;
    padding-left: 4vw;
    max-width: calc(1130px + 8vw);
    width: 100%;
    list-style-type: none;
}

.containerBox li {
    display: flex;
    padding: 35px 0 33px;
    border-bottom: 1px solid #e0dddd;
    flex-wrap: wrap;
    cursor: pointer;
}

.term-name {
    margin-bottom: 0;
    font-size: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.3;
    font-weight: 700;
    color: #6c5ce7;
}

.post-title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
}

.post-excerpt {
    color: #999;
    font-size: 15px;
    line-height: 1.5;
}

.serve-header {
    text-align: center;
}
</style>