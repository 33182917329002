import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Solution from "@/views/Solution.vue";
import Download from "@/views/Download.vue";
import Aboutus from "@/views/Aboutus.vue";
import Help from "@/views/Help.vue";
import HelpList from "@/views/HelpList.vue";
import HelpCont from "@/views/HelpCont.vue";
import MobileAboutus from "@/views/mobile/MobileAboutus.vue";
import MobileHome from "@/views/mobile/MobileHome.vue";
import MobileSolution from "@/views/mobile/MobileSolution.vue";
import MobileScenario from "@/views/mobile/MobileScenario.vue";
import MobildeDownload from "@/views/mobile/MobildeDownload.vue";
import MobildeDocument from "@/views/mobile/MobildeDocument.vue";
import MobildeAssets from "@/views/mobile/MobildeAssets.vue";

const isMobile = () => {
 return /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(
  navigator.userAgent
 );
};

const routes = [
 {
  path: "/",
  name: "home",
  component: Home,
 },
 {
  path: "/solution",
  name: "solution",
  component: Solution,
 },
 {
  path: "/download",
  name: "download",
  component: Download,
 },
 {
  path: "/aboutus",
  name: "aboutus",
  component: Aboutus,
 },
 {
  path: "/help",
  name: "help",
  component: Help,
 },
 {
  path: "/helpList",
  name: "helpList",
  component: HelpList,
 },
 {
  path: "/helpCont",
  name: "helpCont",
  component: HelpCont,
 },
];

if (isMobile()) {
 routes.unshift(
  {
   path: "/",
   name: "mobileHome",
   component: MobileHome,
  },
  {
   path: "/mobile_solution",
   name: "mobileSolution",
   component: MobileSolution,
  },
  {
   path: "/mobile_scenario",
   name: "mobileScenario",
   component: MobileScenario,
  },
  {
   path: "/mobilde_document",
   name: "mobildeDocument",
   component: MobildeDocument,
  },
  {
   path: "/mobilde_assets",
   name: "mobildeAssets",
   component: MobildeAssets,
  },
  {
   path: "/mobilde_download",
   name: "mobildeDownload",
   component: MobildeDownload,
  },
  {
   path: "/mobilde_aboutus",
   name: "mobildeAboutus",
   component: MobileAboutus,
  }
 );
}

const router = createRouter({
 history: createWebHistory(import.meta.env.BASE_URL),
//  history : createWebHistory(),
 routes: routes,
//  scrollBehavior(to, from, savedPosition) {
//     console.log('to',to)
//     console.log('from',from)

//     if(from.name == 'helpCont' && to.name == 'helpList') {
//         console.log('-------ian22')
//         return {}};
//     if (savedPosition) {
//         return {}
//       } else {
//         return { top: 0, behavior: 'smooth' }
//       }
//     return { top: 0, behavior: 'smooth' };

// }
});

export default router;
