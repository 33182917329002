<template>
  <div>
    <div class="img-conatiner pr">
      <img
        class="round-right ps"
        src="../../assets/images/circle_right@2x.34206bf.png"
      />
      <img
        class="top-bg-line ps"
        src="../../assets/images/banner_line@2x.57ad004.png"
      />
      <img class="round-left ps" src="../../assets/images/circle_left.png" />
      <img
        class="round-01 ps"
        src="../../assets/images/circle_right@2x.34206bf.png"
      />
    </div>
    <div class="header-zindex ef-Header">
      <a class="ef-logo">
        <img src="../../assets/images/logo3.png" />
      </a>
      <div class="ef-nav">
        <div class="ef-nav-icon" @click="openBox()">
          <img src="../../assets/images/ef_nav_icon.png" />
        </div>
        <div :class="{ isShow_box: isbox }" class="ef-nav-content">
          <a @click="$router.push('/')">{{ $t("message.ubitapp") }}</a>
          <a @click="$router.push('/mobile_solution')">{{
            $t("message.home_kfzf1")
          }}</a>
          <a @click="$router.push('/mobilde_download')">{{
            $t("message.xzapp")
          }}</a>
          <a @click="otc()">{{ $t("message.foot_cjwt") }}</a>
          <a @click="$router.push('/help')">{{ $t("message.bzzxx") }}</a>
        </div>
      </div>

      <div class="language-switcher">
        <button @click="changeLanguage('en')">English</button>
        <button @click="changeLanguage('zh')">中文</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mobile_top",
  data() {
    return {
      isbox: true,
    };
  },
  methods: {
    openBox() {
      this.isbox = !this.isbox;
    },
    otc() {
      this.$router.push({
        path: "/helpList", // 路由路径
        query: {
          code: "otc", // 参数
        },
      });
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.commit("updateListDataSelf");
    },
  },
};
</script>
<style lang="scss">
.language-switcher {
  position: absolute;
  top: 20px;
  right: 80px;
  display: flex;
  gap: 5px;
  color: #ffeed9;
}
.language-switcher button {
  background: #505050;
  border: 1px solid #505050;
  padding: 5px;
  cursor: pointer;
  border-radius: 8px;
}
.language-switcher button:hover {
  background: #f0f0f0; /* 设置悬停效果 */
}

.img-conatiner {
  z-index: 0;
  .round-right {
    width: 26.667vw;
    top: 0;
    right: 0;
  }
  .top-bg-line {
    width: 100%;
    top: 80vw;
  }
  .round-left {
    width: 26.667vw;
    top: 93.333vw;
  }
  .round-01 {
    width: 26.667vw;
    top: 133.333vw;
    right: 0;
  }
  .ps {
    position: absolute;
  }
}
.ef-Header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5.333vw 5.333vw 0;
  .ef-logo {
    img {
      width: 22.133vw;
      height: 6.4vw;
      margin-top: 1.333vw;
    }
  }
  .ef-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    .ef-nav-icon {
      width: 8.533vw;
      height: 5.333vw;
      background: #ddba82;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-left: 2.667vw;
      border-radius: 1.067vw;
      position: relative;
    }
    .ef-nav-content {
      -webkit-animation-duration: 0.3s;
      animation-duration: 0.3s;
      width: 38.667vw;
      height: 67.733vw;
      border: 1px solid #3d3832;
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#111),
        to(#222)
      );
      background: linear-gradient(180deg, #111, #222);
      z-index: 1;
      position: relative;
      overflow: hidden;
      opacity: 0.85;
      border-radius: 1.867vw;
      right: 0;
      bottom: -69.867vw;
      z-index: 999;
      position: absolute;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      a {
        -webkit-box-align: center;
        -ms-flex-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        font-size: 3.2vw;
        // border-radius: 1.867vw;
        font-family: MicrosoftYaHei;
        color: #ddba82;
        border-bottom: 1px solid #3d3832;
      }
    }
    .isShow_box {
      display: none;
    }
  }
}
</style>
