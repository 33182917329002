<template>
 <div class="mobile_Home">
  <div>
   <div class="ef-container">
    <div class="ef-content pr">
     <TopMobile></TopMobile>
     <div class="ef-intro">
      <div class="ef-intro-title">
       <h1>
        {{ $t("message.qkljxcuks") }}
       </h1>
       <h3>{{ $t("message.wncxdz") }}</h3>
       <span class="ef-line"></span>
       <h2>
        {{ $t("message.syjmhb") }}
       </h2>
      </div>
      <a @click="goUb()" class="common-btn">
       <img src="../../assets/images/home_app_logo.png" />
       <p class="download-words">{{ $t("message.xzapp") }}</p>
      </a>
     </div>
    </div>
    <div class="ef-slogan">
     <div class="ef-slogan-item" :key="item" v-for="item in slogan_item">
      <img :src="item.imgSrc" />
      <span>{{ item.itemSpan }}</span>
      <p>{{ item.itemP }}</p>
     </div>
    </div>
    <div class="ef-detail pr">
     <div class="ef-wallet">
      <div class="ef-title">
       <h1>{{ $t("message.home_dlqb") }}</h1>
       <h2>{{ $t("message.home_qsglndzc") }}</h2>
       <span class="ef-line"></span>
      </div>
      <div class="ef-img-box">
       <img class="app-money-img ps" :src="app_phone1" />
       <img class="ef-show-img" :src="app_phone" />
       <img class="ef-icon1" :src="app_phone2" />
      </div>
      <div class="animate__fadeInUp">
       <div class="ef-desc-item" :key="itm" v-for="itm in ef_desc_item">
        <img :src="itm.imgSrc" />
        <div class="right-con">
         <h1>{{ itm.itemSpan }}</h1>
         <p>{{ itm.itemP }}</p>
        </div>
       </div>
      </div>
     </div>
     <div class="trading">
      <div class="ef-title">
       <h1>{{ $t("message.home_cwjtpt1") }}</h1>
       <h2>{{ $t("message.home_yjkfbjmdh") }}</h2>
       <span class="ef-line"></span>
      </div>
      <div class="animate__fadeInUp">
       <div class="ef-desc-item" :key="ix" v-for="(im, ix) in trading_item">
        <h1>
         <i>{{ `0${ix + 1}` }}</i>
         <span>{{ im.itemSpan }}</span>
        </h1>
        <div class="ef-img-box">
         <img :src="im.imgSrc" />
        </div>
        <p>{{ im.itemP }}</p>
       </div>
      </div>
     </div>
     <div class="pay">
      <div class="ef-title">
       <h1>{{ $t("message.home_kfzf1") }}</h1>
       <h2>{{ $t("message.home_rgjnjmhblj") }}</h2>
       <span class="ef-line"></span>
      </div>
      <div class="tab-box">
       <a class="tab">
        <div class="shadow">
         <img src="../../assets/images/home_pay_01.png" />
        </div>
        <span>{{ $t("message.home_jjfan") }}</span>
       </a>
       <a class="tab">
        <div class="shadow">
         <img src="../../assets/images/home_pay_02.png" />
        </div>
        <span>{{ $t("message.home_yycj1") }}</span>
       </a>
       <a class="tab">
        <div class="shadow">
         <img src="../../assets/images/home_pay_03.png" />
        </div>
        <span>{{ $t("message.home_kfwd1") }}</span>
       </a>
       <a class="tab">
        <div class="shadow">
         <img src="../../assets/images/home_pay_04.png" />
        </div>
        <span>{{ $t("message.home_sq1") }}</span>
       </a>
      </div>
      <div class="ef-other">
       <h3>{{ $t("message.home_zcpt1") }}</h3>
       <div class="ef-icons">
        <img src="../../assets/images/pt_android.png" />
        <img src="../../assets/images/pt_apple.png" />
        <img src="../../assets/images/pt_html5.png" />
        <img src="../../assets/images/pt_ie.png" />
       </div>
      </div>
      <!-- <div class="ef-partners">
                            <div class="ef-title">
                                <h1>生态伙伴</h1>
                                <span class="ef-line"></span>
                            </div>
                            <div class="ef-link"></div>
                        </div> -->
     </div>
     <FooterMobile></FooterMobile>
    </div>
   </div>
  </div>
 </div>
</template>
<script>
import TopMobile from "@/components/mobile/MobileTop.vue";
import FooterMobile from "@/components/mobile/MobileDown.vue";
export default {
 name: "mobile_Home",
 components: {
  FooterMobile,
  TopMobile,
 },
 data() {
  return {
   slogan_item: [
    {
     imgSrc: new URL("../../assets/images/merit_icon01.png", import.meta.url)
      .href,
     itemSpan: this.$t("message.home_jd"),
     itemP: this.$t("message.home_zcjzjd"),
    },
    {
     imgSrc: new URL("../../assets/images/merit_icon02.png", import.meta.url)
      .href,
     itemSpan: this.$t("message.home_anq"),
     itemP: this.$t("message.home_dcbhjsbzn"),
    },
    {
     imgSrc: new URL("../../assets/images/merit_icon03.png", import.meta.url)
      .href,
     itemSpan: this.$t("message.home_sx"),
     itemP: this.$t("message.home_ygfkrgfu"),
    },
    {
     imgSrc: new URL("../../assets/images/merit_icon04.png", import.meta.url)
      .href,
     itemSpan: this.$t("message.home_js1"),
     itemP: this.$t("message.home_qxmsqdhb"),
    },
   ],
   ef_desc_item: [
    {
     imgSrc: new URL("../../assets/images/home_app_icon01.png", import.meta.url)
      .href,
     itemSpan: this.$t("message.home_zcdzjmzc"),
     itemP: this.$t("message.home_wmkfzcqbdgd"),
    },
    {
     imgSrc: new URL("../../assets/images/home_app_icon02.png", import.meta.url)
      .href,
     itemSpan: this.$t("message.home_ubnzz0ssx"),
     itemP: this.$t("message.home_zztsqbmf"),
    },
    {
     imgSrc: new URL("../../assets/images/home_app_icon03.png", import.meta.url)
      .href,
     itemSpan: this.$t("message.home_jzjdsfk"),
     itemP: this.$t("message.home_gbpfmycz"),
    },
   ],
   trading_item: [
    {
     imgSrc: new URL("../../assets/images/otc_img01.png", import.meta.url).href,
     itemSpan: this.$t("message.home_kfbjmhbzydh"),
     itemP: this.$t("message.home_kysyrhbzdh"),
    },
    {
     imgSrc: new URL("../../assets/images/otc_img02.png", import.meta.url).href,
     itemSpan: this.$t("message.home_jdkszyhl"),
     itemP: this.$t("message.home_xyjdtxzfbz"),
    },
    {
     imgSrc: new URL("../../assets/images/otc_img03.png", import.meta.url).href,
     itemSpan: this.$t("message.home_kbzzf"),
     itemP: this.$t("message.home_rgxyzfxjsk"),
    },
   ],

   // isShow: [true, true, true, true],
  };
 },
 watch: {
  "$i18n.locale"() {
   this.slogan_item = [
    {
     imgSrc: new URL("../../assets/images/merit_icon01.png", import.meta.url)
      .href,
     itemSpan: this.$t("message.home_jd"),
     itemP: this.$t("message.home_zcjzjd"),
    },
    {
     imgSrc: new URL("../../assets/images/merit_icon02.png", import.meta.url)
      .href,
     itemSpan: this.$t("message.home_anq"),
     itemP: this.$t("message.home_dcbhjsbzn"),
    },
    {
     imgSrc: new URL("../../assets/images/merit_icon03.png", import.meta.url)
      .href,
     itemSpan: this.$t("message.home_sx"),
     itemP: this.$t("message.home_ygfkrgfu"),
    },
    {
     imgSrc: new URL("../../assets/images/merit_icon04.png", import.meta.url)
      .href,
     itemSpan: this.$t("message.home_js1"),
     itemP: this.$t("message.home_qxmsqdhb"),
    },
   ];
   this.ef_desc_item = [
    {
     imgSrc: new URL("../../assets/images/home_app_icon01.png", import.meta.url)
      .href,
     itemSpan: this.$t("message.home_zcdzjmzc"),
     itemP: this.$t("message.home_wmkfzcqbdgd"),
    },
    {
     imgSrc: new URL("../../assets/images/home_app_icon02.png", import.meta.url)
      .href,
     itemSpan: this.$t("message.home_ubnzz0ssx"),
     itemP: this.$t("message.home_zztsqbmf"),
    },
    {
     imgSrc: new URL("../../assets/images/home_app_icon03.png", import.meta.url)
      .href,
     itemSpan: this.$t("message.home_jzjdsfk"),
     itemP: this.$t("message.home_gbpfmycz"),
    },
   ];
   this.trading_item = [
    {
     imgSrc: new URL("../../assets/images/otc_img01.png", import.meta.url).href,
     itemSpan: this.$t("message.home_kfbjmhbzydh"),
     itemP: this.$t("message.home_kysyrhbzdh"),
    },
    {
     imgSrc: new URL("../../assets/images/otc_img02.png", import.meta.url).href,
     itemSpan: this.$t("message.home_jdkszyhl"),
     itemP: this.$t("message.home_xyjdtxzfbz"),
    },
    {
     imgSrc: new URL("../../assets/images/otc_img03.png", import.meta.url).href,
     itemSpan: this.$t("message.home_kbzzf"),
     itemP: this.$t("message.home_rgxyzfxjsk"),
    },
   ];
  },
 },
 computed: {
  app_phone() {
   return new URL(
    `../../assets/images/${this.$t("message.app_phone")}`,
    import.meta.url
   ).href;
  },
  app_phone1() {
   return new URL(
    `../../assets/images/${this.$t("message.app_phone1")}`,
    import.meta.url
   ).href;
  },
  app_phone2() {
   return new URL(
    `../../assets/images/${this.$t("message.app_phone2")}`,
    import.meta.url
   ).href;
  },
 },
 methods: {
  goUb() {
   window.open("https://www.yubituser.com", "_blank");
  },
 },
};
</script>
<style lang="scss">
html,
body {
 height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
 margin: 0;
}
.mobile_Home {
 .ef-container {
  margin: 0 auto;
  min-height: 100vh;
  color: #888683;
  background: -webkit-gradient(
   linear,
   left top,
   left bottom,
   from(#111),
   to(#222)
  );
  background: linear-gradient(180deg, #111, #222);
  .ef-content {
   width: 100%;
   background-size: contain;
   height: 177.867vw;

   .ef-intro {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 32.8vw;
    .ef-intro-title {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     h1 {
      font-size: 7.467vw;
      text-align: center;
      font-weight: 600;
      color: #ddba82;
      line-height: 10.667vw;
     }
     h3 {
      height: 4.533vw;
      font-size: 3.2vw;
      font-weight: 400;
      color: #888683;
      margin-top: 2.133vw;
      line-height: 4.533vw;
     }
     .ef-line {
      width: 6.133vw;
      height: 0.533vw;
      background: #ddba82;
      border-radius: 0.267vw;
      margin: 1.333vw 0 2.667vw;
     }
     h2 {
      height: 5.867vw;
      font-size: 4.267vw;
      font-weight: 400;
      color: #ddba82;
      line-height: 5.867vw;
     }
    }
    .common-btn {
     width: 90%;
     margin-top: 82.667vw;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
     -webkit-transition: all 0.3;
     transition: all 0.3;
     height: 13.333vw;
     line-height: 13.333vw;
     background: #ddba82;
     color: #2d2c2b;
     font-family: PingFangSC-Semibold, PingFang SC;
     font-weight: 600;
     border-radius: 1.867vw;
     font-size: 3.733vw;
     img {
      width: 6.4vw;
      margin-right: 1.067vw;
     }
     .download-words {
      color: #2d2c2b;
      margin: 0;
     }
    }
   }
  }
  .ef-slogan {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -ms-flex-pack: distribute;
   justify-content: space-around;
   margin: 8vw 5.333vw 0;
   padding: 5.333vw;
   border-radius: 1.867vw;
   border: 1px solid #3d3832;
   z-index: 1;
   position: relative;
   overflow: hidden;
   opacity: 0.85;
   background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(29, 28, 27, 0.8)),
    to(rgba(45, 44, 43, 0.8))
   );
   background: linear-gradient(
    1turn,
    rgba(29, 28, 27, 0.8),
    rgba(45, 44, 43, 0.8)
   );
   .ef-slogan-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 8vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    img {
     height: 10.667vw;
     margin: 5.333vw 0;
    }
    span {
     font-size: 4.267vw;
     color: #ddba82;
     line-height: 4.267vw;
     margin: 1.067vw 0 2.133vw;
    }
    p {
     color: #888683;
     text-align: center;
     font-size: 3.2vw;
     font-weight: 400;
     line-height: 4.8vw;
     width: 36.533vw;
    }
   }
  }
  .ef-detail {
   .ef-wallet {
    margin-top: 12vw;
    .ef-title {
     margin-bottom: 5.333vw;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     margin-bottom: 8vw;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
     h1 {
      font-size: 5.333vw;
      font-weight: 600;
      color: #ddba82;
      line-height: 7.467vw;
     }
     h2 {
      color: #888683;
      font-size: 2.933vw;
      font-weight: 400;
      text-align: center;
      line-height: 4.267vw;
     }
     .ef-line {
      width: 6.133vw;
      height: 0.533vw;
      background: #ddba82;
      border-radius: 0.267vw;
      margin: 1.333vw 0 2.667vw;
     }
    }
    .ef-img-box {
     position: relative;
    }
    .app-money-img {
     animation: ol-baloon-7c9d956b 3s 0s infinite;
     left: 12.621vw;
     width: 81.512vw;
     margin: 1.333vw auto 0;
     display: block;
     top: 32.024vw;
     position: absolute;
    }
    .ef-show-img {
     width: 61.765vw;
     margin: 1.333vw auto 0;
     display: block;
    }
    .ef-icon1 {
     position: absolute;
     animation: ol-baloon-7c9d956b 3s 0.3s infinite;
     left: 5.867vw;
     top: 53.576vw;
     width: 85vw;
    }
    .ef-desc-item {
     border-radius: 1.867vw;
     border: 1px solid #3d3832;
     z-index: 1;
     position: relative;
     overflow: hidden;
     opacity: 0.85;
     margin: 8vw 5.333vw 6.4vw;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     padding: 3.733vw;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     img {
      width: 9.6vw;
      height: 9.6vw;
      margin-right: 3.467vw;
     }
     .right-con {
      h1 {
       font-size: 4vw;
       margin-bottom: 1.867vw;
       color: #ddba82;
      }
      p {
       font-size: 3.2vw;
       font-weight: 400;
       color: #888683;
      }
     }
    }
   }
   .trading {
    .ef-title {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     margin-bottom: 8vw;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
     h1 {
      font-size: 5.333vw;
      font-weight: 600;
      color: #ddba82;
      line-height: 7.467vw;
     }
     h2 {
      color: #888683;
      font-size: 2.933vw;
      font-weight: 400;
      text-align: center;
      line-height: 4.267vw;
     }
     .ef-line {
      width: 6.133vw;
      height: 0.533vw;
      background: #ddba82;
      border-radius: 0.267vw;
      margin: 1.333vw 0 2.667vw;
     }
    }
    .animate__fadeInUp {
     .ef-desc-item {
      border-radius: 1.867vw;
      border: 1px solid #3d3832;
      margin: 8vw 5.867vw;
      padding: 0 4vw;
      h1 {
       font-weight: 400;
       margin-top: 5.333vw;
       font-size: 4vw;
       font-family: DINAlternate-Bold, DINAlternate;
       font-weight: 700;
       color: #888683;
       display: -webkit-box;
       display: -ms-flexbox;
       display: flex;
       -webkit-box-align: center;
       -ms-flex-align: center;
       align-items: center;
      }
      i {
       font-size: 5.6vw;
       line-height: 8vw;
       font-weight: 900;
       margin-right: 1.333vw;
       font-style: normal;
       width: 8vw;
       height: 8vw;
      }
      span {
       font-family: PingFangSC-Regular, PingFang SC;
       font-weight: 400;
       color: #ddba82;
      }
      .ef-img-box {
       height: 50.667vw;
       border-radius: 0.533vw;
       display: -webkit-box;
       display: -ms-flexbox;
       display: flex;
       -webkit-box-pack: center;
       -ms-flex-pack: center;
       justify-content: center;
       -webkit-box-align: center;
       -ms-flex-align: center;
       align-items: center;
       margin: 5.333vw auto 3.2vw;
       background: #2d2c2b;
       border-radius: 1.867vw;
       img {
        width: 52vw;
        height: 36.533vw;
       }
       p {
        font-family: PingFangSC-Regular, PingFang SC;
        font-size: 3.2vw;
        line-height: 5.6vw;
        color: #888683;
       }
      }
     }
    }
   }
   .pay {
    margin-top: 20vw;
    .ef-title {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     margin-bottom: 8vw;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
     h1 {
      font-size: 5.333vw;
      font-weight: 600;
      color: #ddba82;
      line-height: 7.467vw;
     }
     h2 {
      color: #888683;
      font-size: 2.933vw;
      font-weight: 400;
      text-align: center;
      line-height: 4.267vw;
     }
     .ef-line {
      width: 6.133vw;
      height: 0.533vw;
      background: #ddba82;
      border-radius: 0.267vw;
      margin: 1.333vw 0 2.667vw;
     }
    }
    .tab-box {
     padding: 0 5.333vw;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -ms-flex-wrap: wrap;
     flex-wrap: wrap;
     -webkit-box-pack: justify;
     -ms-flex-pack: justify;
     justify-content: space-between;
     .tab {
      z-index: 1;
      overflow: hidden;
      opacity: 0.85;
      width: 41.333vw;
      height: 22.4vw;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 6.4vw 0;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-size: 3.733vw;
      position: relative;
      overflow: inherit;
      border: 1px solid #3d3832;
      border-radius: 1.067vw;
      .shadow {
       width: 17.333vw;
       height: 17.333vw;
       border-radius: 50%;
       z-index: 1;
       position: relative;
       overflow: hidden;
       opacity: 0.85;
       border: 1px solid #3d3832;
       top: -8.667vw;
       bottom: 0;
       background: #222;
       opacity: 1;
       position: absolute;
       padding: 6vw;
       img {
        width: 5.333vw;
       }
      }
      span {
       position: absolute;
       z-index: 100;
       font-family: MicrosoftYaHei;
       color: #ddba82;
       font-size: 2.933vw;
       bottom: 5.333vw;
      }
     }
    }
    .ef-other {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column;
     h3 {
      font-size: 3.2vw;
      margin-bottom: 5.067vw;
      font-weight: 400;
      color: #ddba82;
     }
     .ef-icons {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      img {
       width: 8.267vw;
       height: 9.867vw;
       margin: 0 4.667vw;
      }
     }
    }
   }
  }
  .pr {
   position: relative;
  }
 }
 @keyframes ol-baloon-7c9d956b {
  0% {
   -webkit-transform: translateY(0);
   transform: translateY(0);
  }
  50% {
   -webkit-transform: translateY(10px);
   transform: translateY(10px);
  }

  100% {
   -webkit-transform: translateY(0);
   transform: translateY(0);
  }
 }
}
</style>
