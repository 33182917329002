import { createStore } from "vuex";
import i18n from "../i18n";
const $t = i18n.global.t;

function getLocale() {
  return i18n.global.locale;
}

function getListData() {
  console.log(`ian_locale ${getLocale()}`);
  return [
    {
      key: "A-OTC",
      listTit: "A-OTC",
      data: [
        {
          key: "help_rhmru",
          titel: $t("message.help_rhmru"),
          content:
            $t("message.help_zmmb11") +
            $t("message.help_zmmb12") +
            $t("message.help_zmmb13") +
            $t("message.help_zmmb14"),
          link: [
            {
              h1: $t("message.help_zmmb11"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/buy_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zmmb12"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/buy_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zmmb13"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/buy_3.jpg`,
                import.meta.url
              ).href,

              image1: new URL(
                `../assets/images/cont/${getLocale()}/buy_4.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zmmb14"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/buy_5.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
        {
          key: "help_zmmb2",
          titel: $t("message.help_zmmb2"),
          content:
            $t("message.help_zmmb21") +
            $t("message.help_zmmb22") +
            $t("message.help_zmmb23") +
            $t("message.help_zmmb24"),
          link: [
            {
              h1: $t("message.help_zmmb21"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/sell_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zmmb22"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/sell_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zmmb23"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/sell_3.jpg`,
                import.meta.url
              ).href,
              image1: new URL(
                `../assets/images/cont/${getLocale()}/sell_4.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zmmb24"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/sell_5.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
        {
          key: "help_zmmb3",
          titel: $t("message.help_zmmb3"),
          link: [
            {
              h1: $t("message.help_zmmb31"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/cancel_sell_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zmmb32"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/cancel_sell_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zmmb33"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/cancel_sell_3.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },

        {
          key: "help_zmmb5",
          titel: $t("message.help_zmmb5"),
          content:
            $t("message.help_zmmb51") +
            $t("message.help_zmmb52") +
            $t("message.help_zmmb53"),
          link: [
            {
              h1: $t("message.help_zmmb51"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zmmb52"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zmmb53"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_3.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zmmb54"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_4.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
      ],
    },
    {
      key: "help_jyxg",
      listTit: $t("message.help_jyxg"),
      data: [
        {
          key: "help_jyxg1",
          titel: $t("message.help_jyxg1"),
          link: [
            {
              h1: $t("message.help_jyxg11"),
            },
          ],
        },
        {
          key: "help_jyxg2",
          titel: $t("message.help_jyxg2"),
          link: [
            {
              h1: $t("message.help_jyxg21"),
            },
          ],
        },
      ],
    },
    {
      key: "help_zhxg",
      listTit: $t("message.help_zhxg"),
      data: [
        {
          key: "help_zhxg1",
          titel: $t("message.help_zhxg1"),
          link: [
            {
              h1: $t("message.help_zhxg11"),
            },
            {
              h1: $t("message.help_zhxg12"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/zhxg_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zhxg13"),
              subh1: $t("message.help_zhxg13sub"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/zhxg_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zhxg14"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/zhxg_3.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
        {
          key: "help_zhxg3",
          titel: $t("message.help_zhxg3"),
          link: [
            {
              h1: $t("message.help_zhxg31"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_login_password_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zhxg32"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_login_password_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zhxg33"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_login_password_3.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_zhxg34"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_login_password_4.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
        {
          key: "help_bdgg4",
          titel: $t("message.help_bdgg4"),
          link: [
            {
              h1: $t("message.help_bdgg41"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/bind_google_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_bdgg42"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/bind_google_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_bdgg43"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/bind_google_3.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_bdgg44"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/bind_google_4.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
        {
          key: "help_bdyhk5",
          titel: $t("message.help_bdyhk5"),
          link: [
            {
              h1: $t("message.help_bdyhk51"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/add_payment_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_bdyhk52"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/add_payment_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_bdyhk53"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/add_payment_3.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_bdyhk54"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/add_payment_4.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_bdyhk55"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/add_payment_5.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
      ],
    },
    {
      key: "help_tcwt",
      listTit: $t("message.help_tcwt"),
      data: [
        {
          key: "help_tcwt1",
          titel: $t("message.help_tcwt1"),
          link: [
            {
              h1: $t("message.help_tcwt11"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/cb_4.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_tcwt12"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/cb_5.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_tcwt13"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/cb_6_1.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
        {
          key: "help_tcwt2",
          titel: $t("message.help_tcwt2"),
          link: [
            {
              h1: $t("message.help_tcwt21"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/cb_4.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_tcwt22"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/cb_5.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_tcwt23"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/cb_6.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
        {
          key: "help_tcwt3",
          titel: $t("message.help_tcwt3"),
          link: [
            {
              h1: $t("message.help_tcwt31"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/cb_7.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_tcwt32"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/cb_8.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_tcwt33"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/cb_9.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
      ],
    },
    {
      key: "help_exszn",
      listTit: $t("message.help_exszn"),
      data: [
        {
          key: "help_exszn1",
          titel: $t("message.help_exszna1"),
          link: [
            {
              h1: $t("message.help_exszna11"),
            },
            {
              h1: $t("message.help_exszna12"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/zhxg_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszna13"),
              subh1: $t("message.help_zhxg13sub"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/zhxg_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszna14"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/zhxg_3.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },

        {
          key: "help_exszn3",
          titel: $t("message.help_exszn3"),
          link: [
            {
              h1: $t("message.help_exszn31"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_login_password_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn32"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_login_password_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn33"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_login_password_3.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn34"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_login_password_4.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
        {
          key: "help_exszn4",
          titel: $t("message.help_exszn4"),
          link: [
            {
              h1: $t("message.help_exszn41"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/bind_google_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn42"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/bind_google_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn43"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/bind_google_3.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn44"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/bind_google_4.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
        {
          key: "help_exszn6",
          titel: $t("message.help_exszn6"),
          link: [
            {
              h1: $t("message.help_exszn61"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn62"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn63"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_3.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn64"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/revise_4.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
        {
          key: "help_exszn7",
          titel: $t("message.help_exszn7"),
          link: [
            {
              h1: $t("message.help_exszn71"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/kf_1.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
        {
          key: "help_exszn8",
          titel: $t("message.help_exszn8"),
          link: [
            {
              h1: $t("message.help_exszn81"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/mb_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn82"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/mb_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn83"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/mb_3.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
        {
          key: "help_exszn9",
          titel: $t("message.help_exszn9"),
          link: [
            {
              h1: $t("message.help_exszn91"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/zc_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn92"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/zc_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn93"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/zc_3.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
        {
          key: "help_exszn10",
          titel: $t("message.help_exszn10"),
          link: [
            {
              h1: $t("message.help_exszn101"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/buy_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn102"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/buy_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn103"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/buy_3.jpg`,
                import.meta.url
              ).href,

              image1: new URL(
                `../assets/images/cont/${getLocale()}/buy_4.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn104"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/buy_5.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
        {
          key: "help_exszn11",
          titel: $t("message.help_exszn11"),
          link: [
            {
              h1: $t("message.help_exszn111"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/sell_1.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn112"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/sell_2.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn113"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/sell_3.jpg`,
                import.meta.url
              ).href,
              image1: new URL(
                `../assets/images/cont/${getLocale()}/sell_4.jpg`,
                import.meta.url
              ).href,
            },
            {
              h1: $t("message.help_exszn114"),
              image: new URL(
                `../assets/images/cont/${getLocale()}/sell_5.jpg`,
                import.meta.url
              ).href,
            },
          ],
        },
      ],
    },
    {
      key: "help_fxytk",
      listTit: $t("message.help_fxytk"),
      data: [
        {
          key: "help_fxytk1",
          titel: $t("message.help_fxytk1"),
          code: "serve",
          link: "",
        },
        {
          key: "help_fxytk2",
          titel: $t("message.help_fxytk2"),
          code: "secrecy",
          link: "",
        },
      ],
    },
  ];
}

export default createStore({
  state: {
    list: "",
    cont: {
      data: "",
      idx: 0,
    },
    listData: getListData(),
  },
  mutations: {
    updateList(state, payload) {
      state.list = payload;
    },
    updateCont(state, payload) {
      state.cont = payload;
    },

    updatelistData(state, payload) {
      state.listData = payload;
    },

    updateListDataSelf(state) {
      state.listData = getListData();
      state.listData = getListData();
    },

    fetchList(state, query) {
      var list = getListData().filter((list) => list.key == query.listKey);
      state.list = list[0] || null;
    },

    fetchCont(state, query) {
      var cont =
        getListData()
          .filter((list) => list.key == query.listKey)[0]
          ?.data.filter((cont) => cont.key == query.contKey)[0] || null;
      console.log("fetchCont = ", cont);
      state.cont = { data: cont, idx: query.idx };
    },
  },
  actions: {},
  modules: {},
});
